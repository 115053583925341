import React from 'react';
import { Link } from 'react-router-dom';
import Banner from '../shared/Banner';
import Carousel from './Carousel';

export default function HomePage() {
  return (
    <div className="container">
      <div className="home-container">
        <Banner buttons={true} />
        <Carousel />
        <section>
          <h2>London's Women's Night Safety Charter</h2>
          <p>The Charter is part of the Mayor's Tackling Violence Against Women and Girls Strategy
          and London's commitment to the UN Women Safe Cities and Safe Public Spaces global
          initiative.</p>
          <p>It's all about taking practical steps together to make London at night safer for women.
          Everyone should feel empowered to play their part in creating positive change. Whatever
          your size, shape or area of work, we encourage you to pledge your commitment to
          women's safety.</p>
          <p>This toolkit is packed full of ideas, prompts and signposts. These are practical steps
          towards change. Some will take time, but there will be others you can do today. Some may
          require additional resources, but many will not.</p>
          <p>Whatever steps your organisation is able to take, you can be sure: every step will make a
          difference. Whether you're already proud of the great work you've been doing for women's
          safety or are curious about what you could try, <Link to="/contents">access the toolkit</Link> to find out where to start.</p>
        </section>
      </div>
    </div>
  );
}