import React from 'react';
import ButtonLink from '../shared/ButtonLink';

export default function Nav() {
  return (
    <nav className="nav">
      <ul>
        <li><ButtonLink label="Introduction" className="btn btn-large" to="/introduction"><span>Introduction</span><span></span></ButtonLink></li>
        <li><ButtonLink label="Making a change" className="btn btn-large" to="/making-a-change"><span>Making a change</span><span></span></ButtonLink></li>
        <li><ButtonLink label="Women's night safety and you: A checklist for action" className="btn btn-large" to="/checklist-for-action"><span>Women's night safety and you: A checklist for action</span><span></span></ButtonLink></li>
        <li><ButtonLink label="Putting the commitments into action" className="btn btn-large" to="/commitments-into-action"><span>Putting the commitments into action</span><span></span></ButtonLink></li>
        <li><ButtonLink label="Further information" className="btn btn-large" to="/information"><span>Further information</span><span></span></ButtonLink></li>
      </ul>
    </nav>
  );
}