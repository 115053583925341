import React from 'react';
import ButtonLink from './ButtonLink';

interface IProps {
  title?: string | null;
  buttons?: boolean;
}

export default function Banner(props: IProps) {
  const { title = null, buttons = false } = props;

  return (
    <div className="banner">
      {!title ? (
      <h1><span>Women's Night Safety</span> <span>Charter Toolkit</span></h1>
      ) : (
      <h1><span>{title}</span></h1>
      )}
      {buttons && (
        <ButtonLink label="Access the toolkit" to="/contents" className="btn btn-large">Access the toolkit</ButtonLink>
      )}
    </div>
  );
}