import React, { useState, useEffect } from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';
import { IChecklistAllItems } from '../../misc/IChecklist';
import getChecklist from '../../misc/checklist';
import ChecklistItem from '../shared/ChecklistItem';

// 'any' because can't import RouteComponentProps
export default function ChecklistPage(props: any) {
  const [checklist, setChecklist] = useState<IChecklistAllItems>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getChecklist()
      .then((data: IChecklistAllItems ) => {
        setChecklist(data);
        setLoading(false);
      });
  },
  []);

  return (
    <div className="container">
      <Banner title="Women's night safety and you: A checklist for action" />
      <section>
        <Back previous={props.location.state} />
        <h1>Women's night safety and you: A checklist for action</h1>
        {loading && (
          <div className="skeleton-wrapper">
            <table className="checklist-items">
              <caption>Checklist</caption>
              <thead>
                <tr>
                  <th className="checklist-item-status" scope="col"></th>
                  <th className="checklist-item-name" scope="col">Checklist</th>
                </tr>
              </thead>
            </table>
            <div className="skeleton"></div>
          </div>
        )}
        {!loading && (
        <table className="checklist-items">
          <caption>Checklist</caption>
          <thead>
            <tr>
              <th className="checklist-item-status" scope="col"></th>
              <th className="checklist-item-name" scope="col">Checklist</th>
            </tr>
          </thead>
          <tbody>
            {checklist && (
              <>
                {Object.keys(checklist).map((key) => (
                <tr key={key}>
                  <td colSpan={2} className="checklist-items">
                    <table className="checklist-items">
                      <tbody>
                      {checklist[key].fields.map((field) => (
                        <ChecklistItem
                          key={field.key}
                          item={field}
                        />
                      ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        )}
      </section>
    </div>
  );
}