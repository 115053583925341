import React from 'react';
import 'bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/shared/Layout';
import './styles/index.scss';

function App() {
  return (
    <div className="App">
      <Router>
        <Layout />
      </Router>
    </div>
  );
}

export default App;
