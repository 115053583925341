import React from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';

// 'any' because can't import RouteComponentProps
export default function InformationPage(props: any) {
  return (
    <div className="container">
      <Banner title="Introduction" />
      <section className="truncate-links">
        <Back previous={props.location.state} />
        <h2>Further information</h2>
          <h3>Organisations that can help you</h3>
          <p>Contact these specialised organisations for information on training, designing safety,
          accessibility and diversity resources:</p>
          <ul className="no-list">
            <li>Attitude is Everything (for disability and access advice and guidance)<br /><a href="http://www.attitudeiseverything.org.uk/" title="Attitude is Everything (for disability and access advice and guidance) (opens in a new tab/window)" target="_blank" className="text-truncate">www.attitudeiseverything.org.uk</a></li>
            <li>Design and Access (for disability audits for general buildings)<br /><a href="https://www.designandaccess.co.uk/" title="Design and Access (for disability audits for general buildings) (opens in a new tab/window)" target="_blank" className="text-truncate">www.designandaccess.co.uk</a></li>
            <li>Good Night Out Campaign CIC (training and licensed premises accreditation)<br /><a href="http://www.goodnightoutcampaign.org/" title="Good Night Out Campaign CIC (training and licensed premises accreditation) (opens in a new tab/window)" target="_blank" className="text-truncate">www.goodnightoutcampaign.org</a></li>
            <li>Rape and Sexual Abuse Support Centre (for training and campaign support)<br /><a href="https://www.rasasc.org.uk/" title="Rape and Sexual Abuse Support Centre (for training and campaign support) (opens in a new tab/window)" target="_blank" className="text-truncate">www.rasasc.org.uk</a></li>
            <li>Mayor’s Office for Policing and Crime<br /><a href="https://www.london.gov.uk/what-we-do/mayors-office-policing-and-crime-mopac" title="Mayor’s Office for Policing and Crime (opens in a new tab/window)" target="_blank" className="text-truncate">www.london.gov.uk/what-we-do/mayors-office-policing-and-crime-mopac</a></li>
            <li>MPS Safer Neighbourhood Team<br /><a href="https://www.met.police.uk/a/your-area/" target="_blank" className="text-truncate" title="Visit MPS Safer Neighbourhood Team (opens in a new tab/window)">www.met.police.uk/a/your-area/</a></li>
            <li>London VAWG Consortium (to find your local frontline women’s support service)<br /><a href="https://www.thelondonvawgconsortium.org.uk/" title="London VAWG Consortium (to find your local frontline women’s support service) (opens in a new tab/window)" target="_blank" className="text-truncate">www.thelondonvawgconsortium.org.uk</a></li>
            <li>End Violence Against Women (for specialist women’s support services)<br /><a href="https://www.endviolenceagainstwomen.org.uk/" title="End Violence Against Women (for specialist women’s support services) (opens in a new tab/window)" target="_blank" className="text-truncate">www.endviolenceagainstwomen.org.uk</a></li>
            <li>Suzy Lamplugh Trust (campaigning, education and support with a focus on stalking)<br /><a href="https://www.suzylamplugh.org/" title="Suzy Lamplugh Trust (campaigning, education and support with a focus on stalking) (opens in a new tab/window)" target="_blank" className="text-truncate">www.suzylamplugh.org</a></li>
            <li>The Havens (specialist centres in London for people who have been raped or sexually
              assaulted)<br /><a href="https://www.thehavens.org.uk/" title="The Havens (specialist centres in London for people who have been raped or sexually
                assaulted) (opens in a new tab/window)" target="_blank" className="text-truncate">www.thehavens.org.uk</a></li>
            <li>Southall Black Sisters (specialist support for BAME women)<br /><a href="https://southallblacksisters.org.uk/" title="Southall Black Sisters (specialist support for BAME women) (opens in a new tab/window)" target="_blank" className="text-truncate">www.southallblacksisters.org.uk</a></li>
            <li>Galop (specialist support for LGBT+ women)<br /><a href="https://galop.org.uk/" title="Galop (specialist support for LGBT+ women) (opens in a new tab/window)" target="_blank" className="text-truncate">www.galop.org.uk</a></li>
            <li>Safer and the City (free street-smart app to safely navigate cities)<br /><a href="https://www.safeandthecity.com/" title="Safer and the City (free street-smart app to safely navigate cities) (opens in a new tab/window)" target="_blank" className="text-truncate">www.safeandthecity.com</a></li>
            <li>Safer Business Network CIC (Women's Night Safety Charter Scoping Report)<br /><a href="https://www.saferbusiness.org.uk/post/next-steps-for-the-women-s-night-safety-charter-in-londonsafer-business-network-working-with-mayor" title="Safer Business Network CIC (Women’s Night Safety Charter Scoping Report) (opens in a new tab/window)" target="_blank" className="text-truncate">www.saferbusiness.org.uk/post/next-steps-for-the-women-s-night-safety-charter-in-londonsafer-business-network-working-with-mayor</a></li>
          </ul>
          <h3>Further reading and resources</h3>
          <ul className="no-list">
            <li>Equality and Human Rights Commission – Ending sexual harassment at work<br /><a href="https://www.equalityhumanrights.com/sites/default/files/ending-sexual-harassment-at-work.pdf" title="Equality and Human Rights Commission – Ending sexual harassment at work (opens in a new tab/window)" target="_blank" className="text-truncate">www.equalityhumanrights.com/sites/default/files/ending-sexual-harassment-at-work.pdf</a></li>
            <li>UN Women – Towards and end to sexual harassment<br /><a href="https://www.unwomen.org/en/digital-library/publications/2018/11/towards-an-end-to-sexual-harassment" title="UN Women – Towards and end to sexual harassment (opens in a new tab/window)" target="_blank" className="text-truncate">www.unwomen.org/en/digital-library/publications/2018/11/towards-an-end-to-sexual-harassment</a></li>
            <li>House of Commons Women and Equalities Committee – Sexual harassment of women
            and girls in public places<br /><a href="https://publications.parliament.uk/pa/cm201719/cmselect/cmwomeq/701/701.pdf" title="House of Commons Women and Equalities Committee – Sexual harassment of women
            and girls in public places (opens in a new tab/window)" target="_blank" className="text-truncate">www.publications.parliament.uk/pa/cm201719/cmselect/cmwomeq/701/701.pdf</a></li>
            <li>Advice for Businesses – Business Crime Reduction Hub<br /><a href="https://heartoflondonbid.london/wp-content/uploads/2017/06/ADVICE-FOR-BUSINESSES.pdf" title="Advice for Businesses – Business Crime Reduction Hub (opens in a new tab/window)" target="_blank" className="text-truncate">heartoflondonbid.london/wp-content/uploads/2017/06/ADVICE-FOR-BUSINESSES.pdf</a></li>
          </ul>
      </section>
    </div>
  );
}