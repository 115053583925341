import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from './components/home/HomePage';
import ContentsPage from './components/contents/ContentsPage';
import IntroPage from './components/introduction/IntroPage';
import ChangePage from './components/change/ChangePage';
import ChecklistPage from './components/checklist/ChecklistPage';
import CommitmentsPage from './components/commitments/CommitmentsPage';
import InformationPage from './components/information/InformationPage';
import SupportPage from './components/support/SupportPage';

export default (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/contents" component={ContentsPage} />
    <Route exact path="/introduction" component={IntroPage} />
    <Route exact path="/making-a-change" component={ChangePage} />
    <Route exact path="/checklist-for-action" component={ChecklistPage} />
    <Route exact path="/commitments-into-action" component={CommitmentsPage} />
    <Route exact path="/information" component={InformationPage} />
    <Route exact path="/support" component={SupportPage} />
  </Switch>
);
