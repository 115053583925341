import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header>
      <div className="header-background"></div>
      <div className="header-top">
        <div className="container">
          <div className="navbar-partner">
            &nbsp;
          </div>
          <div className="navbar-partner">
            <Link to="/"><img src="/images/mayor-of-london.svg" alt="Mayor of London" /></Link>
          </div>
          <div className="navbar-partner">
            <a href="https://tillr.io" target="_blank"><span>Powered by</span><img src="/images/tillr-powered.svg" alt="Powered by Tillr" /></a>
          </div>
        </div>
      </div>
    </header>
  );
}