import React from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';

// 'any' because can't import RouteComponentProps
export default function IntroPage(props: any) {
  return (
    <div className="container">
      <Banner title="Introduction" />
      <section>
        <Back previous={props.location.state} />
        <h2>Introduction</h2>
        <p><strong>All women have the right to enjoy London after dark. Sounds obvious, doesn't it? And yet
        the four million women and girls living here still face many barriers when taking part in our
        city's life at night.</strong></p>
        <div className="with-quote with-quote--right">
          <blockquote><p>"I just want to be free and move around like a bird."</p></blockquote>
          <p>While we know that Londoners are actually no more likely to be a victim of crime at night
        than during the day, a combination of factors create an environment where women in
        particular are less likely to feel safe, as shown in our 2018 report into <a href="https://www.london.gov.uk/sites/default/files/london-at-night-full-final.pdf" title="London at night: An evidence base for a 24-hour city (opens in a new tab/window)" target="_blank">London at Night</a>.</p>
        </div>
        <p>We also know that women and girls still face much higher levels of sexual violence,
        assault and abuse than the rest of society at night.</p>
        <p>We know too that sexual offences have the highest levels of under-reporting. This is often
        because victims fear being blamed or not being believed, or don't know or don't trust the
        reporting process. Whether she is your employee or your customer, you can help her feel
        safer when speaking out.</p>
        <p>We believe that sexual harassment, in all its forms, is unacceptable. We are calling on
        businesses, organisations and services that operate in London at night to step up and be
        part of a huge change. <strong>It's about time, don't you think?</strong></p>
        <hr />
        <h3>The Bigger Picture</h3>
        <p><strong>All societies have 'social norms' around gender. These are deeply held cultural ideas
        about behaviour and qualities, which can include how we should look, act and behave,
        whether at home, at work or on a night out. Those who feel entitled to commit violence
        against women often use the idea that a woman has breached these norms as an excuse
        for their own actions. In this way, violence against women and girls is deeply related to
        women's inequality. Organisations that wish to further an agenda of equality need to take a
        stand against violence against women and challenge these restrictive social norms. That
        way, everyone can live their lives to their fullest potential.</strong></p>
        <p>According to the End Violence Against Women Coalition, experiences often play a role in
        stopping women achieving equality in our society. These daily, widespread and often
        normalised incidents limit women's freedom and choices, forcing them to make
        calculations about what is and is not safe for them to do. The expectation or fear of sexual
        harassment or assault, whether in public or at work, leaves women in a constant state of
        managing what author and Dr. Fiona Vera-Gray at Durham University calls 'the right
        amount of panic.'</p>
        <div className="with-quote">
          <blockquote><p>"I take a massive detour after my night shift because the quickest
        route has no lighting at all."</p></blockquote>
          <p>The scale of the problem is huge. While statistics show only a partial picture due to low
        levels of reporting and high levels of normalisation and minimisation ("it's not a big deal, it
        happens all the time, he probably didn't mean it"), we do know that night time venues, such
        as pubs, bars and clubs, can play host to a range of unacceptable behaviours. </p>
        </div>
        <div className="pull-out">
          <p>A YouGov poll of 2,013 adults aged between 18 and 24 who drink in bars, clubs or pubs
          found that:</p>
          <ul>
            <li>72% said they had seen some form of sexual harassment</li>
            <li>63% of women and 26% of men said they had experienced unacceptable behaviour</li>
            <li>79% of women said they expected inappropriate comments, touching and
            behaviour on a night out toward them or their friends</li>
          </ul>
        </div>
        <p>The emotional impact shouldn't be understated either. Alcohol awareness charity
        Drinkaware's 'Drunken Nights Out' Survey asked those who had been on the receiving
        end of drunken sexual harassment for their emotional responses. When asked whether
        they had felt disgust, anger, fear, or surprise at the harassment, the top response for
        women was disgust (74%) followed by anger (63%). The lowest was surprise (14%). </p>
        <p>Unacceptable behaviour is also high in our places of work. A ComRes study for the BBC of
        2,000 adults found that in British workplaces half of British women and a fifth of men have
        been sexually harassed. Of the women who said they had been harassed, 63% said they
        didn't report it to anyone. </p>
        <p>The Equality Act of 2010 deems sexual harassment as a form of unlawful discrimination,
        yet we know that this behaviour is too often laughed off as 'banter'. Wherever sexual
        harassment or assault happens, we must call it out and ensure this behaviour has clear
        consequences. </p>
        <hr />
        <h3>London's Women's Night Safety Charter</h3>
        <p><strong>The Charter is part of the Mayor's Tackling Violence Against Women and Girls Strategy
        and London's commitment to the UN Women Safe Cities and Safe Public Spaces global
        initiative.</strong></p>
        <div className="with-quote">
          <blockquote><p>"I should be able to make my way home after an evening with
        friends on the night bus without being sexually harassed."</p></blockquote>
          <p>It's all about taking practical steps together to make London at night safer for women.
        Everyone should feel empowered to play their part in creating positive change. Whatever
        your size, shape or area of work, we encourage you to pledge your commitment to
        women's safety. </p>
        </div>
        <p>This toolkit is packed full of ideas, prompts and signposts. These are practical steps
        towards change. Some will take time, but there will be others you can do today. Some may
        require additional resources, but many will not. </p>
        <p>Whatever your organisation's size, shape or area of work, we encourage you to pledge. Whether you're already proud of the great work you've been doing for women's
        safety or are curious about what you could try, read on to find out where to start. </p>
        <hr />
        <h3>What does it mean to sign the Charter?</h3>
        <p>The Women's Night Safety Charter is a voluntary pledge to show you take women's safety
        seriously.</p>
        <p>By signing the Charter, you show your acknowledgement of the issue and that you're
        ready to get proactive in improving women's safety and experience. We're building a
        strong network dedicated to making a positive change to women's experiences across
        London at night.</p>
        <p>Whatever your organisation does for London at night, there will be something you can do
        to better support women's safety.</p>
        <blockquote><p>"When I'm working at the club, I should be confident that my
        manager will do something if some guy won't leave me alone."</p></blockquote>
      </section>
    </div>
  );
}