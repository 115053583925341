import React, { useState, useEffect } from 'react';
import { IChecklistItem } from '../../misc/IChecklist';
import useLocalStorage from '../../misc/useLocalStorage';

interface IProps {
  item: IChecklistItem;
}

export default function ChecklistItem(props: IProps) {
  const { item } = props;
  const [, setModel] = useState<{ [key: string]: boolean }>();
  const [itemsChecked, setItemsChecked] = useLocalStorage<{ [key: string]: boolean }>('', {});
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setModel(itemsChecked);
    if (itemsChecked && typeof itemsChecked[item.key] !== 'undefined') {
      setChecked(itemsChecked[item.key]);
    }
  },
  []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemsChecked((prevState) => ({
      ...prevState,
      [item.key]: event.currentTarget.checked,
    }));
    setChecked(event.currentTarget.checked);
  };

  return (
    <tr>
      <td className="checklist-item-status">
        <input
          className="custom-checkbox"
          onChange={handleChange}
          type="checkbox"
          checked={checked}
        />
      </td>
      <td>{item.label}</td>
    </tr>
  );
}