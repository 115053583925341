import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Banner from '../shared/Banner';
import Back from '../shared/Back';

// 'any' because can't import RouteComponentProps
export default function ChangePage(props: any) {
  return (
    <div className="container">
      <Banner title="Making a change" />
      <section>
        <Back previous={props.location.state} />
        <h2>Making a change</h2>
        <p>Of course, we want sexual harassment, assault and other forms of gender-based violence
        to stop. However, we can all commit to do more, which is what the Women’s Night Safety
        Charter is all about. Businesses, organisations and services can’t do everything, but we
        can all do more.</p>
        <div className="pull-out important">
          <h3 className="dark">The Four Rs</h3>
          <p>First, learn your four Rs: <strong>Responsibility, Report, Respond and Redesign.</strong></p>
          <h4><i className="bi bi-check2-square"></i> Responsibility</h4>
          <p>Who is responsible for unacceptable behaviour? The person doing it! It’s a simple
          equation, and yet we know victim-blaming culture still holds sway in too many places of
          work and play. Organisations that bring people together at night have a duty of care to
          ensure their spaces do not further enable harassment, assault or violence towards women
          or anyone else. It’s also important to recognise that taking responsibility is not the same as
          taking liability.</p>
          <h4><i className="bi bi-check2-square"></i> Report</h4>
          <p>A total end to these often criminal behaviours is a long way off and you can’t prevent every
          single incident. When it does happen, reporting it should be a simple, discreet and hasslefree choice. The process for what will happen next should be clear with a range of options
          for how an incident can be dealt with.</p>
          <h4><i className="bi bi-check2-square"></i> Respond</h4>
          <p>Choosing to speak up should be encouraged through promoting a supportive culture that
          believes those who do come forward. Staff should feel confident and clear on how to
          respond to these reports in a consistent, professional and empathetic way that prioritises
          safety and care, not personal judgement.</p>
          <h4><i className="bi bi-check2-square"></i> Redesign</h4>
          <p>We know that alongside experiences of harassment or assault, the perception of ‘safety’
          plays into women’s choices when avoiding certain areas, services and businesses.
          Nightlife spaces should be designed to be welcoming and to minimise the risk of enabling
          ‘opportunistic’ perpetrators, who may, for instance, use a lack of clear visibility in a venue
          to their advantage.</p>
        </div>
        <p>Based on our research and the backing of specialist support, policy and training
        organisations working in this area, we have put together seven commitments for women’s
        night safety.</p>
        <h3 className="dark">The Seven Commitments</h3>
        <table className="commitments">
          <caption>The Seven Commitments</caption>
          <tbody>
            <tr>
              <th scope="row">1. Champion</th>
              <td>Appointing a named contact for this work who will champion
              and drive forward any action taken</td>
              <td className="td-small"><Link to="/commitments-into-action#champion">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">2. Communicate</th>
              <td>Positive, public/staff facing communications campaign,
              both online and in your space(s)</td>
              <td className="td-small"><Link to="/commitments-into-action#communicate">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">3. Support your staff</th>
              <td>Routes for reporting unacceptable behaviour while at
              work and supporting cultural change</td>
              <td className="td-small"><Link to="/commitments-into-action#support-your-staff">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">4. Support the public</th>
              <td>Routes for reporting unacceptable behaviour while using
              your service or space at night</td>
              <td className="td-small"><Link to="/commitments-into-action#support-the-public">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">5. Training: responding</th>
              <td>Staff training on the issue, including what to say/do and
              not say/do, and any relevant policies</td>
              <td className="td-small"><Link to="/commitments-into-action#training-responding">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">6. Training: recording </th>
              <td>Staff training on information sharing and appropriate
              recording of details</td>
              <td className="td-small"><Link to="/commitments-into-action#training-recording">More info</Link></td>
            </tr>
            <tr>
              <th scope="row">7. Designing for Safety</th>
              <td>Audit your spaces and adapt them to promote a safer
              environment and reduce risk of crime</td>
              <td className="td-small"><Link to="/commitments-into-action#designing-for-safety">More info</Link></td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}