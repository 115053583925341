export function getUrlPrefix(): string {
  if (window.location.hostname.startsWith('local')) {
    return 'local';
  }
  if (window.location.hostname.startsWith('uat')) {
    return 'uat';
  }
  if (window.location.hostname.startsWith('demo')) {
    return 'demo';
  }
  return '';
}

export function getGAKey(): string {
  const key: string = getUrlPrefix().length ? getUrlPrefix() : 'prod';
  const keys: { [key: string]: string } = {
    'local': 'G-WKKDW5WXET',
    'uat': 'G-3EQWEG132Z',
    'prod': 'G-S98W0DDZR5',
  };
  return keys[key];
}

export function scrollToSmoothly(position: number, duration: number): void {
  let pos = position;
  let time = duration;
  if (typeof pos !== 'number') {
    pos = parseFloat(pos);
  }
  if (isNaN(pos)) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw 'Position must be a number';
  }
  if (pos < 0 || time < 0) {
    return;
  }
  const currentPos = window.scrollY || window.screenTop;
  let start: null | number = null;
  time = time || 500;
  window.requestAnimationFrame(function step(currentTime: number) {
    start = !start ? currentTime : start;
    if (currentPos < pos) {
      let progress = currentTime - start;
      window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    } else {
      let progress = currentTime - start;
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    }
  });
}

export function ScrollDetect(): void {
  const body = document.body;
  let lastScrollTop = 0;
  const scrollTrigger = 100;

  window.addEventListener('scroll', () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      if (st > scrollTrigger) {
        body.classList.add('body--scrolled-active');
        body.classList.remove('body--scrolled-inactive');
      }
    } else {
      if (st <= scrollTrigger) {
        body.classList.remove('body--scrolled-active');
        body.classList.add('body--scrolled-inactive');
      }
    }
    lastScrollTop = st <= 0 ? 0 : st;
  }, false);
}
