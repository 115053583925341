import React, { useEffect } from 'react';
import Rellax from 'rellax';
import Cover from './Cover';
import Header from './Header';
import Footer from './Footer';
import routes from '../../routes';
import BottomNav from './BottomNav';
import { ScrollDetect } from '../../misc/utils';

export default function Layout() {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rellax = new Rellax('.rellax', { });
    ScrollDetect();
  },
  []);

  return (
    <>
      <div className="app__wrapper">
        <div className="app__root">
          <Header />
          <Cover />
          <main className="app__content rellax" data-rellax-speed="5">
            {routes}
          </main>
          <Footer />
        </div>
      </div>
      <BottomNav />
    </>
  );
}