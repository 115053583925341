import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer>
      <div className="container max-width">
        <div className="row">
          <div className="col-12 col-sm-3">
            <h5>Women's Night Safety Charter</h5>
            <p><Link to="/contents">Access the toolkit</Link></p>
          </div>
          <div className="col-12 col-sm-3">
            <address>
              Greater London Authority<br />
              City Hall<br />
              The Queen’s Walk<br />
              London SE1 2AA<br />
            </address>
            <p className="mb-0"><a href="https://www.london.gov.uk/about-us/contacting-city-hall-and-mayor" target="_blank" title="Contact City Hall and the Mayor (opens in a new tab/window)" className="nav-link p-0">Contact</a></p>
            <p><a href="https://www.london.gov.uk" target="_blank" title="Visit www.london.gov.uk (opens in a new tab/window)" className="nav-link p-0">www.london.gov.uk</a></p>
          </div>
          <div className="offset-sm-3 col-12 col-sm-3">
            <p className="d-flex"><span>Powered by</span><img src="/images/tillr-powered.svg" alt="Powered by Tillr" /></p>
            <p className="mb-0"><a href="mailto:hello@tillr.io" target="_blank" title="Contact tillr.io (opens in a new tab/window)" className="nav-link p-0">Contact</a></p>
            <p><a href="https://tillr.io" target="_blank" title="Visit tillr.io (opens in a new tab/window)" className="nav-link p-0">tillr.io</a></p>
          </div>
        </div>
        <div className="d-flex justify-content-between py-4 my-4 border-top">
          <p>&copy; {year} Greater London Authority</p>
        </div>
      </div>
    </footer>
  );
}