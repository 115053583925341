import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Banner from '../shared/Banner';
import Back from '../shared/Back';
import { IChecklistAllItems } from '../../misc/IChecklist';
import getChecklist from '../../misc/checklist';
import ChecklistItem from '../shared/ChecklistItem';
import { scrollToSmoothly } from '../../misc/utils';

// 'any' because can't import RouteComponentProps
export default function CommitmentsPage(props: any) {
  const [previous, setPrevious] = useState(props.location.state);
  const [activeSection, setActiveSection] = useState<string>();
  const [checklist, setChecklist] = useState<IChecklistAllItems>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getChecklist()
      .then((data: IChecklistAllItems ) => {
        setChecklist(data);
        setLoading(false);
      });

    const hash = window.location.hash;
    if (hash) {
      const id = hash.substring(1, hash.length);
      setActiveSection(id);
      const item = document.getElementById(`item-${id}`);
      if (item) {
        const pos = item.offsetTop;
        scrollToSmoothly(pos + 100, 0);
      }
      setPrevious({ from: '/change' });
    }
  },
  []);

  return (
    <div className="container">
      <Banner title="Putting the commitments into action" />
      <section>
        <Back previous={previous} />
        <h2>Putting the commitments into action</h2>
        <div className="accordion" id="commitments-accordion">
          <div id="item-champion" className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button aria-label="Champion" className={clsx('accordion-button', activeSection !== 'champion' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded={ activeSection === 'champion' } aria-controls="collapseOne">
                <span>1</span>
                <span>Champion</span>
              </button>
            </h2>
            <div id="collapseOne" className={clsx('accordion-collapse', 'collapse', activeSection === 'champion' && 'show')} aria-labelledby="headingOne">
              <div className="accordion-body">
                {/* <!-- 1 Champion -->
                <!-- 1 Champion : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['0'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      item={field}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <h4>What is a Champion?</h4>
                <p>A Women's Safety Champion in your workplace is someone who makes a difference
                because they are:</p>
                <ul>
                  <li>Passionate about helping people understand that women's safety is a shared
                  responsibility</li>
                  <li>Able to give time and resources to undertake meaningful activities and advocate for
                  practical and cultural changes</li>
                  <li>Willing to engage colleagues constructively and positively when taking action to
                  address women's safety, and encouraging others to do so too</li>
                  <li>Keen to spread the word about the commitments you have been able to meet</li>
                  <li>Able to empower colleagues to challenge unsafe language, behaviours and practice
                  and support the training agenda</li>
                </ul>
                <h4>Who should the Champion be?</h4>
                <p>The Champion could be a manager, the person responsible for licensing, or a member of
                your HR team if you have one. Ultimately, your organisation will need to decide which
                member of staff best fits the role of the Champion. Passion is more important than
                experience here. Many organisations will hopefully choose a member of staff with the time,
                energy and ability to inspire others to have honest conversations about this issue. Yes,
                men can be a Champion too!</p>
                <h4>What will the Champion do?</h4>
                <p>The Champion will be the point of contact for any work done on this issue, ensuring that the commitments you prioritise are met by your organisation. They will oversee the
                promotion of a culture of belief at your organisation and share best practice with other
                organisations.</p>
                {/* <!-- 1 Champion : END --> */}
              </div>
            </div>
          </div>
          <div id="item-communicate"  className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button aria-label="Communicate" className={clsx('accordion-button', activeSection !== 'communicate' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded={ activeSection === 'communicate' } aria-controls="collapseTwo">
                <span>2</span>
                <span>Communicate</span>
              </button>
            </h2>
            <div id="collapseTwo" className={clsx('accordion-collapse', 'collapse', activeSection === 'communicate' && 'show')} aria-labelledby="headingTwo">
              <div className="accordion-body">
                {/* <!-- 2 Communicate -->
                <!-- 2 Communicate : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['1'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      item={field}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <h4>Why is a communications campaign important?</h4>
                <p>The simple act of developing, distributing and displaying a poster or notice about your
                organisation's values in relation to women's safety and the consequences for
                unacceptable behaviour can have a hugely positive impact. In doing so, you are naming
                something that many organisations have traditionally refused to acknowledge. So publicly
                engaging with the topic could be read as 'we are not a safe place.' In fact, when we look at
                how common these experiences are, the direct opposite is true. Many women and girls are
                reassured to know what the values of that space are and who and where they can go to for
                support. </p>
                <p>Too often, sexual harassment and assault is not spoken about due to fear of not being
                believed, or seen to be a private matter. We know that women's safety should be
                everyone's issue. Communication campaigns let staff and customers know that you take
                safety seriously. Naming your values publicly like this helps to create an environment
                where everyone feels confident to report incidents perpetrators will know that this kind of
                behaviour will not be tolerated.</p>
                <h4>What should this campaign look like?</h4>
                <p>This might take the form of a poster campaign, publishing your harassment policy on your
                website and reminding customers of your dedication to women's safety on your social
                media channels.</p>
                <p>Some dos and don'ts for successful women's safety messaging:</p>
                <p>Dos:</p>
                <ul>
                  <li>Use positive language (e.g. “we aim to create a welcome space for all our
                    customers...")</li>
                  <li>Focus on the consequences for unacceptable behaviour (e.g. “you will be asked to
                    leave...")</li>
                  <li>Include why this is not okay (e.g. “no one should feel unsafe while they are here)</li>
                  <li>Summarise the options you're able to offer for reporting (e.g. text, email, app)</li>
                </ul>
                <p>Don'ts:</p>
                <ul>
                    <li>Use graphic images which can be off-putting</li>
                    <li>Use sensational language or humour when describing these offences </li>
                    <li>Scaremongering or descriptions of victims or perpetrators</li>
                    <li>Use any 'victim-blaming' language (e.g. telling people to stay with their friends and
                      stay alert to their surroundings). While this might seem helpful and well-intentioned,
                      women who are targeted should not have to change their behaviour when it is
                      perpetrators who can make a choice not to harass. Further on in the toolkit we have
                      included more examples of victim-blaming language.</li>
                </ul>
                <figure>
                  <blockquote>
                      <p>“As part of the staff training, we provide posters for display to all the
                      licensed premises we work with, because communication is key.
                      Our posters say 'If something or someone makes you feel
                      uncomfortable, you can speak to any member of our specially
                      trained staff and they will work with you to make sure it doesn't
                      have to ruin your experience.'</p>
                      <p>Communicating your policy in clear language that everyone
                      understands will encourage women to report. Putting it on posters,
                      for example, is one positive solution. With the posters your
                      customers will know what they can expect from your staff and it will
                      give your staff back-up when dealing with a confrontation.”</p>
                  </blockquote>
                  <figcaption>Ester van Kempen, Project Coordinator, Good Night Out Campaign</figcaption>
                </figure>
                {/* <!-- 2 Communicate : END --> */}
              </div>
            </div>
          </div>
          <div id="item-support-your-staff" className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button aria-label="Support your staff" className={clsx('accordion-button', activeSection !== 'support-your-staff' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded={ activeSection === 'support-your-staff' } aria-controls="collapseThree">
                <span>3</span>
                <span>Support your staff</span>
              </button>
            </h2>
            <div id="collapseThree" className={clsx('accordion-collapse', 'collapse', activeSection === 'support-your-staff' && 'show')} aria-labelledby="headingThree">
              <div className="accordion-body">
                {/* <!-- 3 Support your Staff -->
                <!-- 3 Support your staff : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['2'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      item={field}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <h4>How can we create this environment?</h4>
                <p>The Equality Act 2010 defines sexual harassment at work as unwanted conduct of a
                sexual nature which has the purpose or effect of violating someone's dignity, or creating
                an intimidating, hostile, degrading, humiliating or offensive environment for them.</p>
                <p>Sexual harassment, sexual assault and hate crimes more generally are not experienced in
                the same way as other offences. This is in part because they target identity, that is, who a
                person is or is perceived to be. This is very personal. It means that victims often blame
                themselves and remain silent about what happened, whether due to self-blame, fear of
                being blamed, of being disbelieved, or being further victimised.</p>
                <p>Workplaces should acknowledge these barriers and help to reduce and remove them with
                a combination of cultural and practical changes. </p>
                <p>You could:</p>
                <ul>
                  <li>Undertake an anonymous survey of your staff team to get a full view of how safety
                is experienced across the organisation. Who feels safest and in which roles? When
                do particular workers feel the least safe and why?</li>
                  <li>Send a memo to all staff clearly communicating your policy and reminding them that
                all reports are confidential and will be taken seriously</li>
                  <li>Call a special meeting with managers to discuss and plan the different ways
                women's safety could be made a priority, including support the progress and
                leadership journeys of women in your organisation. </li>
                  <li>Address workplace bullying and use administrative measures to vary power
                dynamics, for example rotating who chairs meetings</li>
                  <li>Investigate how skilled your teams feel when it comes to challenging harmful jokes
                or comments in the workplace and role play the best way of doing this to normalise
                it</li>
                  <li>Publish your Sexual Harassment Policy and Equalities Statement online</li>
                  <li>Invest in Sexual Harassment and Equalities training for the whole team</li>
                </ul>
                <figure>
                  <blockquote>
                    <p>“Sometimes the person accused of harassment retaliates by further
                    harassing or bullying the person who has lodged a complaint. Or in
                    some instances an employer might treat a victim of sexual
                    harassment unfairly because they are perceived as being 'difficult'
                    or causing problems in a team. Your employer is not allowed to
                    victimise you for complaining about sex discrimination or sexual
                    harassment at work. If you are victimised for complaining, or for
                    helping a colleague to make a complaint, you can make a claim for
                    unlawful victimisation to an employment tribunal.”</p>
                  </blockquote>
                    <figcaption>Your Rights at Work; Protection from Sexual Harassment, TUC</figcaption>
                </figure>
                {/* <!-- 3 Support your staff : END --> */}
              </div>
            </div>
          </div>
          <div id="item-support-the-public" className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button aria-label="Support the public" className={clsx('accordion-button', activeSection !== 'support-the-public' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded={ activeSection === 'support-the-public' } aria-controls="collapseFour">
                <span>4</span>
                <span>Support the public</span>
              </button>
            </h2>
            <div id="collapseFour" className={clsx('accordion-collapse', 'collapse', activeSection === 'support-the-public' && 'show')}  aria-labelledby="headingFour">
              <div className="accordion-body">
                {/* <!-- 4 Support the Public -->
                <!-- 4 Support your public : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['3'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      item={field}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <h4>Why is having a clear policy and offering different reporting methods so important?</h4>
                <p>Having multiple ways to report will make it easier for people to overcome barriers they may
                be facing around sharing their experience with you. Accessibility is also an issue. Women
                with disabilities, including those who are deaf or blind, should feel equally able to make a
                report.</p>
                <figure>
                  <blockquote>
                    <p>"Sexual harassment is very common and gets in the way of the
                    freedom and enjoyment that women and girls can have when out at
                    night. It is crucial that all organisations have specific policies in
                    place on sexual harassment and assault, because this ensures
                    that employees and customers know what behaviours are
                    unacceptable and how complaints will be dealt with. </p>
                    <p>Policies should include your definition of inappropriate conduct,
                    making clear that sexual harassment will not be tolerated. They
                    should be given to staff during induction and training, reviewed
                    regularly and be made accessible and available on your website,
                    staff intranet or handbook. This creates a sense of safety and
                    fairness in the workplace. All staff should be fully aware of how, by
                    whom and within what timeframe any reports will be responded to
                    and investigated."</p>
                  </blockquote>
                  <figcaption>Rebecca Hitchen, Campaigns Manager, End Violence Against Women Coalition</figcaption>
                </figure>
                {/* <!-- 4 Support your public : END --> */}
              </div>
            </div>
          </div>
          <div id="item-training-responding" className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button aria-label="Training: responding" className={clsx('accordion-button', activeSection !== 'training-responding' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded={ activeSection === 'training-responding' } aria-controls="collapseFive">
                <span>5</span>
                <span>Training: responding</span>
              </button>
            </h2>
            <div id="collapseFive" className={clsx('accordion-collapse', 'collapse', activeSection === 'training-responding' && 'show')} aria-labelledby="headingFive">
              <div className="accordion-body">
                {/* <!-- 5 Training: responding -->
                <!-- 5 Training responding : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['4'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      item={field}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <h4>How can I make sure my staff respond the right way?</h4>
                <p>Ensure that every team member who is public facing is confident and has been trained in
                how to take a report of sexual harassment.</p>
                <figure>
                  <blockquote>
                    <p>“We wanted our venue staff to be trained in how to handle sexual
                    harassment as we believe that cultural spaces have a responsibility
                    to do everything within their power to be as safe and welcoming as
                    possible. </p>
                    <p>The training gave the staff the confidence to confidently handle
                    difficult situations with the care and attention that they need and
                    gave us the opportunity to get this done in a thorough,
                    understandable and affordable way.”</p>
                  </blockquote>
                  <figcaption>Deano Jo, owner, Five Miles, a bar, club and brewery in Seven Sisters</figcaption>
                </figure>
                <h4 className="dark">Advice for responding to harassment</h4>
                <p>The person coming forwards has chosen you because they feel able to let you know about
                what has happened. Most people never speak up due to fear of being blamed or
                disbelieved, so the first thing you say is vital.</p>
                <p>Try this fail-safe opener:</p>
                <ul>
                  <li>Demonstrate belief (e.g. “Thank you for sharing what happened.”)</li>
                  <li>Validate their experience (e.g. “That is not okay.”)</li>
                  <li>Explain their options (e.g. “We have a policy here which is…” and “I'm going to see
                    what I can do to help.”)</li>
                  <li>Check you've understood what you've been told</li>
                  <li>Ask about any physical injuries or urgent needs</li>
                </ul>
                <p>Then if necessary support the person in passing up to the relevant manager or team
                member to take this forward. Offer to separately summarise their story on their behalf if
                they would like you to. </p>
                <h5>Communicate</h5>
                <p>You're not alone! Tell a colleague when you are concerned about behaviour you've
                witnessed, no matter how minor it seems, as this prevents escalation.</p>
                <h5>Be an active bystander</h5>
                <p>Responding effectively to harmful behaviour can start with focussing your attention and
                support on the person being targeted. Check in with the person who you think is
                experiencing unwanted attention, for example by making small talk, eye contact or giving
                them a discreet signal. This gives them an option to divert the harasser's attention.</p>
                <h5>Record keeping</h5>
                <p>Record all incidents and reports, no matter how minor. This helps others to keep up to
                date with issues and repeated behaviours. Keep your notes clear and stick to facts not
                opinions.</p>
                <p>You can also ask for and record the name of any customer while they are on the premises,
                but they have the right to refuse to give this information. Remember your CCTV. <strong>Don't
                hesitate to call 101 or 999 in an emergency.</strong> But remember, the targeted person may
                prefer not to speak to the police and should never be coerced into doing so. You will be assisting greatly by providing even general third-party intelligence to the police through
                101, 999 or your local Safer Neighbourhood team. </p>
                <p>A culture of belief can become embedded within your organisation by regularly briefing
                and training staff and including your policy in new staff inductions. Make women's safety,
                inclusion and diversity part of your team culture through daily conversations. This is an
                ongoing process. Staff teams should be encouraged to challenge victim-blaming attitudes
                and managers should provide and encourage learning opportunities on this topic. </p>
                <p>Examples of victim-blaming attitudes:</p>
                <p>“She was plastered at after work drinks, it's no wonder he took advantage”</p>
                <p>“I heard they were having an affair and she's only calling it sexual harassment now
                that he's gone back to his wife”</p>
                <p>“Women do need to bear some responsibility for making bad choices if they end up
                in one of our taxis with some dodgy guy.”</p>
                <p>“It takes two to tango when it comes to sexual assault”</p>
                {/* <!-- 5 Training responding : END --> */}
              </div>
            </div>
          </div>
          <div id="item-training-recording" className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button aria-label="Training: recording" className={clsx('accordion-button', activeSection !== 'training-recording' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded={ activeSection === 'training-recording' } aria-controls="collapseSix">
                <span>6</span>
                <span>Training: recording</span>
              </button>
            </h2>
            <div id="collapseSix" className={clsx('accordion-collapse', 'collapse', activeSection === 'training-recording' && 'show')} aria-labelledby="headingSix">
              <div className="accordion-body">
                {/* <!-- 6 Training: recording -->
                <!-- 6 Training recording : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['5'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      item={field}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <h4>What incidents should be reported and how?</h4>
                <p>All organisations should follow appropriate discretion and confidentiality. Licensed
                premises should make use of their log book. If you have a HR team, consider additional
                training on understanding the dynamics of sexual harassment, assault, gender-based
                violence and other Equality Act issues. Staff should know that no matter how minor the
                incident might seem, it is important that it is recorded – in an appropriate amount of detail
                and in a factual manner without personal opinions.</p>
                <p>You could also:</p>
                <ul>
                  <li>Undertake a data and confidentiality review to ensure that personal details are not
                  being misused</li>
                  <li>Ensure that your HR team – if you have one – has appropriate guidelines to stop
                  the flow of gossip and misinformation if a disclosure has been made by one team
                  member against another</li>
                </ul>
                <p>An official policy and procedure should set out clear expectations and ensure that each
                situation is dealt with fairly regardless of who is involved. This is important because it can
                reduce fear of victimisation. Women's Night Safety Charter signatory the Trades Union
                Congress (TUC) offers advice to staff, acknowledging that women who do speak up about
                sexual harassment should not 'become' the problem in the eyes of their employer. Your
                staff should know their rights.</p>
                <figure>
                  <blockquote>
                    <p>"If there is ever an issue at a DICE event, we want fans to feel
                    supported and listened to when they get in touch. Our support team
                    gets amazing feedback from fans, mostly because they aren't
                    robots and genuinely want people to have amazing nights out as
                    well as deal with any issues that might happen. Our support team
                    has gone through training which was valuable in helping us
                    understand how do deal with disclosures of sexual harassment or
                    assault. Plus, we have several 'Women in Music' events which we
                    use to try and support cultural change."</p>
                  </blockquote>
                  <figcaption>Tom Winterton, Global Head of Comms at DICE</figcaption>
                </figure>
                {/* <!-- 6 Training recording : END --> */}
              </div>
            </div>
          </div>
          <div id="item-designing-for-safety" className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button aria-label="Designing for safety" className={clsx('accordion-button', activeSection !== 'designing-for-safety' && 'collapsed')} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded={ activeSection === 'designing-for-safety' } aria-controls="collapseSeven">
                <span>7</span>
                <span>Designing for safety</span>
              </button>
            </h2>
            <div id="collapseSeven" className={clsx('accordion-collapse', 'collapse', activeSection === 'designing-for-safety' && 'show')} aria-labelledby="headingSeven">
              <div className="accordion-body">
                {/* <!-- 7 Designing for safety -->
                <!-- 7 Designing for safety : START --> */}
                {loading && (
                  <div className="skeleton-wrapper">
                    <table className="checklist-items">
                      <caption>Checklist</caption>
                      <thead>
                        <tr>
                          <th className="checklist-item-status" scope="col"></th>
                          <th className="checklist-item-name" scope="col">Checklist</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="skeleton"></div>
                  </div>
                )}
                {!loading && (
                <table className="checklist-items">
                  <caption>Checklist</caption>
                  <thead>
                    <tr>
                      <th className="checklist-item-status" scope="col"></th>
                      <th className="checklist-item-name" scope="col">Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                  {checklist && checklist['6'].fields.map((field) => (
                    <ChecklistItem
                      key={field.key}
                      item={field}
                    />
                  ))}
                  </tbody>
                </table>
                )}
                <figure>
                  <blockquote>
                    <p>Only when we feel safe can we truly thrive as a city. That is why
                    Safe & The City is proud to support the Women's Night Safety
                    Charter toolkit. Providing a navigation tool and collecting smart-city
                    data powers our ability to design safer and smarter streets together.
                    These insights help to measure our progress as a safe city and
                    showcase businesses who are leading this change at night."</p>
                  </blockquote>
                  <figcaption>Jillian Kowalchuk, Founder & CEO, Safe & The City</figcaption>
                </figure>
                <h4>How can I adapt my space to make it feel safer?</h4>
                <p>If your organisation manages its own spaces, these should always be designed with health
                and safety in mind. The additional 'safety planning' that many women report having to
                undertake on a daily basis to remain alert against unwelcome sexual behaviour tells us
                that further steps can be taken. </p>
                <p>Questions to consider:</p>
                <ul>
                    <li>Does your premises' layout support a woman's ability to move around or leave an
                    area easily? </li>
                    <li>Are there sufficiently lit paths and appropriate levels of CCTV and do all staff
                    understand who is responsible for using, maintaining and sharing this data?</li>
                    <li>Can you designate a 'quiet' space in case staff or customers need some medical
                    assistance or space to recover from an incident?</li>
                    <li>In addition to this, designing for safety can include how you allocate your human
                    resources. Are staff, including any security, positioned equally throughout the
                    spaces you manage?</li>
                    <li>How well-lit are the indoor and outdoor spaces you're responsible for?</li>
                    <li>Would your CCTV be able to record any incident on site? Do you record sound? </li>
                    <li>Can you confidently say your space is accessible for wheelchair users, Deaf people
                    and people with disabilities?</li>
                    <li>Can you confidently say that those who would prefer to use a gender-neutral
                    bathroom would be able to find one in your building?</li>
                </ul>
                <h4>Met Police Safer Neighbourhood Teams</h4>
                <p>Partnering with these teams is great way to of plugging into issues that affect your local
                area. Poor street lighting, security issues and anti-social behaviour can all be discussed
                at regular meetings. These are also a good way of finding out about local good practice
                and initiatives.</p>
                <p><a href="https://www.met.police.uk/a/your-area/" target="_blank" title="Visit MPS Safer Neighbourhood Team (opens in a new tab/window)">MPS Safer Neighbourhood Team</a></p>
                <figure>
                  <blockquote>
                    <p>"We know that feeling safe and comfortable on nights out is a very
                    important part of accessibility, and that Deaf and disabled women
                    experience higher levels of sexual harassment than other women.</p>
                    <p>Attitude is Everything's ethos is that Deaf and disabled people
                    should be as independent as they want to be at live music and
                    cultural events. Venues and events can do this by building equality
                    into the strategic process. Over 190 venues and events have
                    signed up to our Arts Council England-funded Charter of Best
                    Practice, adopting it as an industry standard for accessibility and
                    agreeing to be mystery shopped by our team of Deaf and disabled
                    volunteers. Their detailed feedback on accessibility is then passed
                    onto management, so that any potential issues are identified and
                    practical solutions are suggested. We also offer Disability Equality
                    Training, undertake Access Audits and offer Consultancy to advise
                    on changes that may be required." </p>
                  </blockquote>
                  <figcaption>Suzanne Bull MBE, CEO of Attitude is Everything</figcaption>
                </figure>
                {/* <!-- 7 Designing for safety : END --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}