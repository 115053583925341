import React from 'react';

export default function Carousel() {
  const interval = 5000;
  return (
    <div id="myCarousel" className="carousel slide" data-bs-touch="false" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1"  className="active" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item" data-bs-interval={interval}>
          <div className="container">
            <div className="carousel-caption text-start">
              <p><span>"</span>When I’m working at the club, I should be confident that my
              manager will do something if some guy won’t leave me alone.<span>"</span></p>
            </div>
          </div>
        </div>
        <div className="carousel-item active" data-bs-interval={interval}>
          <div className="container">
            <div className="carousel-caption text-start">
              <p><span>"</span>I take a massive detour after my night shift because the quickest
              route has no lighting at all.<span>"</span></p>
            </div>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval={interval}>
          <div className="container">
            <div className="carousel-caption text-start">
              <p><span>"</span>I should be able to make my way home after an evening with
              friends on the night bus without being sexually harassed.<span>"</span></p>
            </div>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval={interval}>
          <div className="container">
            <div className="carousel-caption text-start">
              <p><span>"</span>I just want to be free and move around like a bird.<span>"</span></p>
            </div>
          </div>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}